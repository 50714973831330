<template>
  <div class="f-box">
    <div class="handle">
      <div class="h-img"><img src="@/assets/img/lip_02.png" alt=""></div>
      <div class="user">
        <span style="margin-right:0;">礼品台编号：</span>
        <span style="margin-right:25px;">{{ taihao }}</span>
        <span style="margin-right:0;">礼品责任人：</span>
        <span>{{username}}</span
        ><el-dropdown trigger="click">
          <span class="el-dropdown-link">
            <i class="el-icon-arrow-down"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="showGiftList">礼品列表</el-dropdown-item>
            <el-dropdown-item @click.native="back">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <!-- <div class="h-btn" @click="showGiftList">礼品列表</div> -->
    </div>
    <!-- <div class="no-table" v-if="Data.length == 0 && !showQList">
          <img src="@/assets/img/hehe_03.png" alt="">
    </div> -->
    <div class="table-box" v-if="!showQList">
      <div class="s-btn">
            <el-input v-model="input" placeholder="请输入抽签顺序号" style="width:350px" @keyup.enter.native="searchGift">
                <el-button slot="append" icon="el-icon" @click="searchGift" style="font-size: 20px;">搜索</el-button>
                <template slot="prepend"><span style="font-size: 20px;line-height: 20px;">VIP</span></template>
            </el-input>
        </div>
      <div class="flex-box">
          <el-table :data="Data" border style="width: 100%" class="table1">
              <el-table-column label="抽签号" prop="sort" align="center">
              </el-table-column>
              <el-table-column label="人数" prop="people" align="center">
              </el-table-column>
              <el-table-column prop="contract" label="合同编号" align="center">
              </el-table-column>
              <el-table-column prop="name" label="权利人姓名" align="center">
              </el-table-column>
              <el-table-column label="签到人姓名" prop="sign_name" align="center">
              </el-table-column>
              <el-table-column label="礼品份数" align="center" prop="gift_num">
              </el-table-column>
              <el-table-column label="是否领取礼品" align="center">
                <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.is_gift"></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column label="是否归还臂贴" align="center">
                <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.huan_xiong"></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column label="是否归还手环" align="center">
                <template slot-scope="scope">
                  <el-checkbox
                    v-model="scope.row.huan_shou"
                  ></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column label="备注" align="center">
                <template slot-scope="scope">
                  <el-input
                    v-model="scope.row.remark"
                    placeholder="请输入"
                    size="small"
                    style="width: 120px"
                  ></el-input>
                </template>
              </el-table-column>
            </el-table>
            <div class="page-btns">
              <div class="sure-btn" @click="upGift">确认提交</div>
            </div>
          </div>
    </div>
    <div class="table-box" v-else>
      <div class="flex-box">
              <el-button type="primary" style="margin-bottom: 20px;" @click="reback">返回</el-button>
              <el-button type="primary" style="margin-bottom: 20px" @click="daoChu">导出</el-button>
              <el-table
                  :data="tableData"
                  style="width: 100%" class="table1">
                  <el-table-column label="抽签号" prop="sort" align="center">
                  </el-table-column>
                  <el-table-column label="人数" prop="people" align="center">
                  </el-table-column>
                  <el-table-column prop="contract" label="合同编号" align="center">
                  </el-table-column>
                  <el-table-column prop="name" label="权利人姓名" align="center">
                  </el-table-column>
                  <el-table-column label="签到人姓名" prop="sign_name" align="center">
                  </el-table-column>
                  <el-table-column label="礼品份数" align="center" prop="gift_num">
                  </el-table-column>
                  <el-table-column label="备注" prop="remark" align="center">
                  </el-table-column>
                  <el-table-column label="是否领取礼品" prop="is_gift_cn" align="center">
                  </el-table-column>
                  <el-table-column label="是否归还手环" prop="huan_shou_cn" align="center">
                  </el-table-column>
                  <el-table-column label="是否归还臂贴" prop="huan_xiong_cn" align="center">
                  </el-table-column>
              </el-table>
              <el-pagination
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-size="10"
                layout="total, prev, pager, next, jumper"
                :total="GData.total">
              </el-pagination>
          </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      arr:[],
      bei:'',
      input:'',
      currentPage:1,
      radio3:'0',
      radio2:'0',
      radio1:'0',
      showQList:false,
      GData:[],
      tableData:[],
      radios:'',
      Data:[],
      Qsort:'',
      taihao:''
    };
  },
  methods: {
    daoChu(){
      let uin = localStorage.getItem('uin')
      window.location.href = $baseUrl+`export/export_gift?useruid=${uin}`
    },
    reback(){
      this.showQList = false;
    },
    showGiftList(){
      this.currentPage = 1;
      this.getGiftList()
      this.showQList = true;
    },
    getGiftList(){
      this.$http.post('/index/gift_list',{page:this.currentPage}).then(res=>{
          if(res.data.code == 0){
            this.GData = res.data.data
            this.tableData = res.data.data.list
          }
      })
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getGiftList()
    },
    // 提交
    upGift(){
        // let dt = {
        //   id:this.Data[0].id,
        //   is_gift:0,
        //   huan_shou:0,
        //   huan_xiong:0,
        //   remark:this.Data[0].remark
        // }
        let arr = []
        this.Data.forEach(item=>{
          if(item.huan_shou){
             item.huan_shou = 1
          }else{
             item.huan_shou = 0
          }
          if(item.huan_xiong){
             item.huan_xiong = 1
          }else{
             item.huan_xiong = 0
          }
          if(item.is_gift){
             item.is_gift = 1
          }else{
             item.is_gift = 0
          }
          arr.push(item)
        })  
        // if(this.Data[0].huan_shou) dt.huan_shou = 1
        // if(this.Data[0].huan_xiong) dt.huan_xiong = 1
        // if(this.Data[0].is_gift) dt.is_gift = 1
        this.$http.post('/index/add_gift',arr).then(res=>{
          if(res.data.code == 0){
            $success('提交成功')
            this.clearData()  
          }
        })
    },
    // 搜索
    searchGift(){
      this.Qsort ='vip'+this.input
      this.$http.post('/index/gift_info',{sort:this.Qsort}).then(res=>{
        if(res.data.code == 0){
          console.log(res)
          let arr = []
          res.data.data.forEach(item => {
            if(item.huan_shou == 0){item.huan_shou = false}else{item.huan_shou = true}
            if(item.huan_xiong == 0){item.huan_xiong = false}else{item.huan_xiong = true}
            if(item.is_gift == 0){item.is_gift = false}else{item.is_gift = true}
            arr.push(item)
          });
            // let arr = []
            // if(res.data.data.huan_shou == 0){res.data.data.huan_shou = false}else{res.data.data.huan_shou = true}
            // if(res.data.data.huan_xiong == 0){res.data.data.huan_xiong = false}else{res.data.data.huan_xiong = true}
            // if(res.data.data.is_gift == 0){res.data.data.is_gift = false}else{res.data.data.is_gift = true}
            // arr.push(res.data.data)
            this.Data = arr
        }
      })
    },
    clearData(){
      this.tableData = this.Data = this.arr = [];
      this.bei = this.input = this.radios = this.Qsort = '';
      this.radio1 = this.radio2 = this.radio3 = 0;
    },
    back() {
      this.$router.push({ path: "/" });
      localStorage.clear();
    },
  },
  created(){
    this.username = localStorage.getItem('name')
    this.taihao = localStorage.getItem('taihao')
  },
};
</script>
<style lang="less" scoped>
/deep/.el-card {
    width: 500px;
    margin: 0 auto;
}
.handle {
  position: relative;
  background: #b5011d;
  height: 60px;
  line-height: 60px;
  padding: 0 30px;
  color: gold;
  font-size: 24px;
  .user {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translate(0, -50%);
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 10px;
    }
    span {
      margin-right: 15px;
    }
  }
}
.s-btn {
  margin: 30px auto 0;
  // text-align: center;
}
.s-msg {
  padding: 90px 0px 0;
  font-size: 16px;
  line-height: 22px;
  .s-title {
    position: relative;
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 10px;
    line-height: 24px;
    margin-bottom: 5px;
    width: 200px;
    &::after {
      content: "";
      width: 120px;
      background: linear-gradient(to right, #000, #fff);
      height: 2px;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
  .t-msg {
    font-size: 14px;
    line-height: 40px;
    .tag {
      & > div {
        width: 300px;
        display: inline-block;
        margin-right: 68px;
      }
      span:first-child {
        margin-right: 20px;
        display: inline-block;
        color: #999;
      }
    }
  }
}
.s-radio {
  width: 1104px;
  padding: 0 30px;
  margin-top: 10px;
  span {
    font-size: 14px;
    color: #999;
    margin-right: 5px;
  }
}
.sure-btn{
    width: 120px;
    height: 40px;
    background: #b7001d;
    font-size: 14px;
    color: gold;
    text-align: center;
    border-radius: 6px;
    line-height: 40px;
    cursor: pointer;
}
.flex-box {
  margin: 60px 40px 0;
  .pagination {
    width: 100%;
    text-align: right;
    margin-top: 50px;
  }
  .none-box{
      display: flex;
      align-items: center;
      margin-top: 20px;
      .none-tip{
        font-size: 18px;
        font-weight: bold;
        display: inline-block;
        width: 190px;
      }
    }
}
/deep/.el-table thead th.is-leaf{
    color: gold;
    background-color: #b7001d!important;
}
/deep/.el-table thead.is-group th{
    color: gold;
    background-color: #b7001d!important;
}
.f-box{
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}
.table-box{
  flex: 1;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
}
.h-btn{
  display: inline-block;
  width: 80px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translate(0,-50%);
  color: #fff;
  font-weight: bold;
  right: 200px;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
}
.s-btn{
    margin: 30px auto 0;
    text-align: center;
}
.page-btns{
  display:flex;
  align-items: center;
  justify-content: center;
  margin:70px 0;
}
.no-table{
  text-align: center;
  width: 100%;
  line-height: 0;
  img{
    width: 700px;
    margin-top: 30px;
  }
}
.h-img{
  line-height: 0;
  height: 100%;
  overflow: hidden;
  img{
    height: 100%;
    margin-top: 4px;
  }
}
/deep/.el-pagination{
  margin: 20px 0;
}
::-webkit-scrollbar {
    display:none;
    width:0;
    height:0;
    color:transparent;
}
</style>